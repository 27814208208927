<template>
    <div>
        <div class="pa-10">
            <v-card flat class="pa-3" v-bind:class="{ 'blue lighten-5' : isBlueBackground }" v-for="(testinfo, testType, idx) in tests" :key="testType">
                <div v-for="(test, index) in getTestByType(testType)" :key="index"> <!-- For loop op dit moment niet nodig, maar wellicht later met subtests wel -->
                    <v-layout row wrap class="mt-6" justify-space-between>
                        <v-flex xs12 md12 v-if="index ===0">
                            <h2>{{testinfo.title}}
                              <span>
                                <v-icon v-if="testinfo.testInstructions" @click.stop="showTestInstructions=true" small color="primary" class="mb-4">mdi-information-outline</v-icon>
                                <TestInstructions v-model="showTestInstructions" :instructions="testinfo.testInstructions"/>
                              </span>
                            </h2>
                        </v-flex>
                        <v-flex xs12 md5 class="mb-2" v-if="index==='undefined' || index ===0">
                            <span v-html="testinfo.description"></span>
                        </v-flex>
                        <v-flex xs12 md5 class="mb-2" v-else>                                    
                        </v-flex> 
                        <v-flex xs4 md1 v-if="testinfo.subtestFieldTitle">
                          <div class="caption grey--text">{{ testinfo.subtestFieldTitle }}</div>
                          <div class="mb-4">{{ test[testinfo.subtestKey] | convertToTitle(testinfo)}}</div>
                        </v-flex>
                        <v-flex xs12 md1 v-else>
                        </v-flex>

                        <!-- If there is agent feedback give 2 spaces in the flexbox for the status field, otherwise just 1 space -->
                        <v-flex xs4 md2 v-if="getAgentServiceFeedback(test) != null && getAgentServiceFeedback(test) != ''">
                            <div class="caption grey--text">Status</div>
                            <div>{{ getCurrentExecution(test) | state }}</div>
                            <div v-if="getAgentServiceFeedback(test) != null && getAgentServiceFeedback(test) != ''">
                              <div style="white-space: pre-line">{{ getAgentServiceFeedback(test) }}</div>
                            </div>
                        </v-flex>
                        <v-flex xs4 md1 v-else>
                            <div class="caption grey--text">Status</div>
                            <div :id="createElementId('status', testType)">{{ getCurrentExecution(test) | state }}</div>
                        </v-flex>

                        <v-flex xs4 md1>
                            <div class="caption grey--text">Voortgang</div>
                            <div :id="createElementId('voortgang', testType)">{{ getCurrentExecution(test) | progress}}</div>
                        </v-flex>                            
                        <v-flex xs4 md1>                            
                            <v-dialog v-model="registerResultPopup[idx * Object.keys(tests).length + index]" width="1000" :retain-focus="false">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-if="testinfo.registerResult" :disabled="isAtRegisterPhase()==false"  v-bind:class="{ 'blue lighten-3' : !isAtRegisterPhase(test) }" outlined block class="primary" v-bind="attrs" v-on="on">Registreer</v-btn>
                              </template>
                              <v-card>
                                <v-card-title>Registreer resultaat</v-card-title>

                                <v-card-subtitle class="primary--text mt-1 subtitle-1">
                                  <span>{{testinfo.registerResultSubtitle}}</span>
                                  <span class=font-weight-bold>{{ test[testinfo.subtestKey] | convertToReadableValue(testinfo)}}</span>
                                </v-card-subtitle>
                                
                                <v-card-text>
                                  Als dit correct is klik op 'success' en laat het opmerkingenveld leeg. Als dit niet klopt geef dan in het opmerkingen veld aan wat er afwijkt en klik op 'gefaald'.
                                </v-card-text>
                                <v-textarea
                                  class="mx-6"
                                  outlined
                                  v-model="registerResultOpmerkingen[idx * Object.keys(tests).length + index]"
                                  label="Opmerkingen"
                                ></v-textarea>
                                <v-card-actions>
                                  <v-btn class="primary" v-on:click="updateExecutieFase(test, testType, true, idx * Object.keys(tests).length + index)">Succes</v-btn>
                                  <v-btn class="primary" v-on:click="updateExecutieFase(test, testType, false, idx * Object.keys(tests).length + index)">Gefaald</v-btn>
                                  <v-spacer></v-spacer>
                                  <v-btn color="error" @click="registerResultPopup[idx * Object.keys(tests).length + index] = false">Sluiten <v-icon small color="white">mdi-cancel</v-icon>
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>

                        </v-flex>
                        <v-flex xs4 md1>
                            <v-btn :id="createElementId('startTest', testType)" outlined block class="primary" v-on:click="startExecution(test, testType)">Start test</v-btn>
                        </v-flex>
                      <v-col cols="12" md="8" class="mt-5">
                        <span id="startExecutionError" v-if="startExecutionErrorTestId == test.id" class="error--text">Er is een fout opgetreden met het starten van de executie. Probeer opnieuw of contact support.</span>
                      </v-col>
                    </v-layout>

                </div>                  
            </v-card>
        </div>
    </div>
</template>

<script>
/**
  *  The testen component consists of the fields for one test: title, description, status, progress and start test button.
  *  Optionally: popup screen for test instructions, subtest information (i.e.  rijstrook number or beeldstand), register test button
  *  component: TestInstructions - popup screen with step by step testinstructions containing images and text 
  */
import gql from 'graphql-tag'
import TestInstructions from '@/components/TestInstructions.vue'

  export default {
    name: 'HardwareTesten',
    /**
     *  index: used to handle subtests. Only show description of the test at the first test, not with every subtest.
     *  registerResultPopup: popup used to manually register the result of a test
     *  registerResultOpmerkingen: comment field used in the manual registration of test results
     *  showTestInstructions: boolean used to determine whether the test instructions popup should be visible or not
     *  startExecutionErrorId: id of test that had an error when starting
     */
    data: () => ({
        index: 0,
        registerResultPopup: {},
        registerResultOpmerkingen: {},
        showTestInstructions: false,
        startExecutionErrorId: ""
    }),
    components: {
      TestInstructions
    },

    /**
     *  tests: Object - metadata of the tests such as type of test, title, description, optionally test instructions, which key to use for subtests and which correpsonding title to show
     *  data: Object - data containing the current executionState of the tests/ This is the result of the Apollo subscriptions
     *  currentHardwareId: String - defines the hardware for which to display the test data (i.e. iRose1, iRose2, etc)
     *  currentExecutionId: String - id of currently active execution
     *  isBlueBackground: boolean - defines whether the background of the test should be blue or not (default is white). Used to differentiate between tests of different iRoses for example.
     */
    props: {
        tests: Object,
        data: Object,
        currentHardwareId: String,
        isBlueBackground: Boolean
    },

    methods: {
    /**
     *  Button click startExecution
     *  Tells the backend that the testType has been initiated. The test Id is taken from 'test'. The current test session ID is taken from the global Vuex storage.
     */      
      startExecution(test, testType) {
        if (test == null) {
          return;
        }

        this.$apollo.mutate({
          // Query
          mutation: gql`mutation startExecutionMutation ($testId: String!, $testType: TestType!) {
              startExecution(testId: $testId, testType: $testType)
          }`,
          // Parameters
          variables: {
            testId: test.id,
            testType: testType,
            hardwareId: test.hardwareId,
            executions: test.executions
          },
        }).then((data) => {
          this.startExecutionErrorId = "";
          // Result
          console.log(JSON.stringify(data));
        }).catch((error) => {
          // Error
          console.error("Error occurred for startExecution of test with id: [" + test.id + "] - " + error);
          this.startExecutionErrorId = test.id;
        })
      },

      // TODO moet toegevoegd worden zodra we tests krijgen met user feedback
      // /**
      //  *  Button click updateExecutieFase
      //  *  Used in the manual registration of test results.
      //  *  Tells the backend that the testtype 'testType' with test Id taken from 'test' has been a success or fail (boolean success parameter).
      //  *  The current test session ID is taken from the global Vuex storage. Execution Id is taken from 'test'.
      //  *  Execution phase and progress is always 2 and 100 respectively at the point that the user registers a result.
      //  *  userFeedback is a textfield that the user can use to add comments.
      //  */
      // updateExecutieFase(test, testType, success, index) {
      //   if (test == null) {
      //     return;
      //   }
      //
      //
      //   this.$apollo.mutate({
      //     // Query
      //     mutation: gql`mutation updateExecutieFaseMutation ($testSessionId: String!, $executieFaseUpdateType: ExecutieFaseUpdateType!, $execId: String!, $executieFaseId: String!, $voortgang: Int!, $status: String!, $userFeedback: String) {
      //         updateExecutieFase(testSessionId: $testSessionId, executieFaseUpdateType: $executieFaseUpdateType, executieId: $execId, executieFaseId: $executieFaseId, voortgang: $voortgang, status: $status, userFeedback: $userFeedback)
      //     }`,
      //     // Parameters
      //     variables: {
      //       testSessionId: this.testSessionId,
      //       executieFaseUpdateType: testType,
      //       execId: this.getCurrentExecutie(test).id,
      //       executieFaseId: 2,
      //       voortgang: 100,
      //       status: success ? "success" : "failed",
      //       userFeedback: this.registerResultOpmerkingen[index] ? this.registerResultOpmerkingen[index] : ""
      //     },
      //   }).then((data) => {
      //     // Result
      //     console.log("Bevestigd: " + JSON.stringify(data));
      //   }).catch((error) => {
      //     // Error
      //     console.error("error: " + error)
      //   })
      //
      //   this.registerResultPopup[index] = false;
      // },

      /**
       *  Method to retrieve the data corresponding to the testType (i.e. IROSE2_CONNECT or IROSE2_RECONNECT)
       */
      getTestByType(testType) {
        if (!this.data.testSessionChanged) return;
        let tests = this.data.testSessionChanged.tests;
        let testByType = tests.filter(test => test.testType == testType);

        if (testByType.length === 1) {
          return Array.of(testByType[0]);
        } else if (testByType.length > 1 && this.currentHardwareId) {
          let testByHardwareId = testByType.filter(test => test.hardwareId == this.currentHardwareId);
          if (testByHardwareId.length > 0) {
            return Array.of(testByType.filter(test => test.hardwareId == this.currentHardwareId)[0]);
          }
        }
        console.warn("No tests found")
      },

      /**
       *  Method to retrieve the current execution
       */
      getCurrentExecution(test) {
        if (test.executions == null || !test.executions.length) {
          console.warn("No executions found for test:" + test.testType);
          return;
        }
        return test.executions[test.executions.length - 1];
      },

     /**
       *  Method returning a boolean to indicate whether the test is at the phase where the user can manually register the result.
       *  This phase is always the second phase.
       */
      isAtRegisterPhase() {
        // if (test.executies == null || !test.executies.length) return false;
        //
        // if (this.getCurrentExecutieFase(test).id == 2) return true;
        //TODO fixen zodra we tests hebben met meerder fases
        return false;

      },
      /**
       *  Method returning the agent service feedback.
       */
      getAgentServiceFeedback(test) {
        
        if (test.executions == null || !test.executions.length) return "";

        let currentExecution = this.getCurrentExecution(test);
        if (currentExecution == null) return "";

        return currentExecution.lastFatClientFeedback;
      },

      /**
       *  Method to create a unique HTML element id used for automatic testing
       */
      createElementId(element, testType) {
        return this.currentHardwareId+"-"+element+"-"+testType;
      },

      /**
       * Method to check if test has an startExecutionError.
       */
      isStartExecutionError(testId) {
        if (!(testId in this.startExecutionErrorId)) {
          return false
        } else {
          return this.startExecutionErrorId[testId];
        }
      }
    },

    computed: {
      /**
       *  testSessionId: currently selected test session ID. Globally stored in the Vuex store.
       */
      testSessionId() {
        return this.$store.getters.getCurrentTestSessionId;
      },


      /**
       *  startExecutionErrorTestId: id of test that has an startExecutionError.
       */
      startExecutionErrorTestId() {
        return this.startExecutionErrorId;
      },
    },
    
    filters: {
      /**
       *  Filter used to display the status of the given execution phase nicely
       */
      state: function (execution) {
        if (execution == null) {
          return "";
        }

        let state = execution.state;

        switch (execution.state) {
          case "INITIALIZED":
            state = "opzetten";
            break;
          case "RUNNING":
            state = "uitvoeren";
            break;
          case "SUCCESS":
            state = "succes";
            break;
          case "FAILED":
            state =  "gefaald";
            break;
        }

        return state;
      },

      /**
       *  Filter used to display the progress with a percentage sign
       */
      progress: function (testExecution) {
        if (testExecution == null) {
          return "";
        }

        return testExecution.currentPhaseProgress + "%";
      },

      /**
       *  Filter used to convert a subtest value to a title, if this was defined in the metadata of the test 
       *  I.e. rijstroken just have subtest titles 1,2,3 etc but MUSsen have 0,1 or 2 converted to A,B,C
       */
      convertToTitle: function (subtestValue, testinfo) {
        if (testinfo.subtestValueToTitle) {
          return testinfo.subtestValueToTitle[subtestValue];
        }
        return subtestValue;
      },

     /**
       *  Filter used to display the registerresult value to a readable text
       * I.e. "(50)"" is converted to "50 met rode rand"
       */
      convertToReadableValue: function (subtestValue, testinfo) {
        if (testinfo.registerResultValueToText && testinfo.registerResultValueToText[subtestValue])
          return testinfo.registerResultValueToText[subtestValue]
        
        if (testinfo.subtestValueToTitle && testinfo.subtestValueToTitle[subtestValue]) {
          return testinfo.subtestValueToTitle[subtestValue];
        }
        return subtestValue;
      }
    }
  }
</script>

<style scoped>
  button.v-btn--disabled{
    background-color: 'blue lighten-5' !important;
}
</style>