<template><v-app>
  <div>
    <div class="pa-6" v-bind:class="{ 'blue lighten-5' : index % 2 === 1 }" v-for="(falconRadar, index) in falconRadars" :key="falconRadar.hardwareId">
      <h2 class="mb-4">Falcon Radar {{falconRadar.hardwareId}}</h2>

      <FalconRadarConfig :falconRadars=createFalconRadarArray(falconRadar) :show-hardware-id="false" :show-delete-button="false" />

      <Testen :tests=falconRadarTests :data=data hardware-key="hardwareId" :current-hardware-id=falconRadar.hardwareId :is-blue-background="index % 2 === 1"/>

    </div>
  </div>
</v-app></template>

<script>
/**
 *  The FalconRadar page contains the tests of all the falcon radars configured in the currently selected test session.
 *  component: FalconRadarConfig - summary table of falcon radars in the current iwks configuration
 *  component: Testen - displays all the tests with current executionState and metadata
 */
import gql from 'graphql-tag'
import FalconRadarConfig from '@/components/FalconRadarConfig.vue'
import Testen from '@/components/Testen.vue'

export default {
  name: 'falconRadar',
  apollo: {
    /**
     *  Subscription on all falcon radar tests for data used to display in the Testen component
     *  variable: currently selected test session
     *  If the first test (connection test) does not contain data then there are no falcon radar tests. Reroute to Profibus testen page.
     */
      $subscribe: {
        subs: {
          query: gql`subscription falconRadarSubscription($id: String!) {
          testSessionChanged(testSessionId: $id){
            iwksConfiguration { falconRadars { hardwareId, host } },
            tests { id, hardwareId, testType, executions, { id, currentPhaseProgress, state, lastFatClientFeedback } }
          }
          }`,
          variables () {
            // Use vue reactive properties here
            return {
                id: this.testSessionId
            }
          },
          // Result hook
          // Don't forget to destructure `data`
          result ({ data }) {
            if (data.testSessionChanged.tests.filter(test => test.testType == "FALCON_RADAR_PING").length === 0) {
              this.$router.push('/koperkoppelvlaktesten'); 
            } else {
              this.data = data;
              this.falconRadars = data.testSessionChanged.iwksConfiguration.falconRadars;
            }
          }
        },
      },
      
    },

    data: () => ({
      /**
       *  falconRadars:  used to store apollo subscription result of the falcon radar configuration in iwksConfiguration
       *  falconRadarTests: metadata of the falcon radar tests
       *  data: used to store apollo subscription result in its entirety 
       */
      falconRadars: [],

      falconRadarTests: {
        FALCON_RADAR_PING: {
          title: "Ping",
          description: "Deze test checkt of het ip-adres van de falcon bereikbaar is.",
        }
      },

      data: {},
    }),

    computed: {
      /**
       *  testSessionId: currently selected test session ID. Globally stored in the Vuex store.
       */
      testSessionId() {
        return this.$store.getters.getCurrentTestSessionId;
      }
    },

    methods: {
      /**
       *  vue-prop-data: the component FalconRadarConfig expects an array. Convert the object to an array of 1 object.
       */
      createFalconRadarArray(falconRadar) {
        let falconRadars = [];
        falconRadars.push(falconRadar);
        return falconRadars;
      }
    },

    components: {
      FalconRadarConfig,
      Testen,
    }
}
</script>