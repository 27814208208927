<template><v-app>
  <div>
    <div class="pa-6">        
      <h2 class="mb-4">UPS</h2>

      <Testen :tests=upstests :data=data current-hardware-id="UPS"/>

    </div>
  </div>
</v-app></template>

<script>
/**
 *  The UPS page contains the UPS tests of the currently selected test session.
 *  component: Testen - displays all the tests with current executionState and metadata
 */
import gql from 'graphql-tag'
import Testen from '@/components/Testen.vue'

export default {
  name: 'UPS',
  apollo: {
    /**
     *  Subscription on all UPS tests for data used to display in the Testen component
     *  variable: currently selected test session
     */
    $subscribe: {
      subs: {
        query: gql`subscription myUpsSubscription($id: String!) {
          testSessionChanged(testSessionId: $id){
            tests { id, hardwareId, testType, executions, { id, currentPhaseProgress, state, lastFatClientFeedback } }
            }
          }`,
        variables () {
          // Use vue reactive properties here
          return {                
              id: this.testSessionId
          }
        },
        // Result hook
        // Don't forget to destructure `data`
        result ({ data }) {
          this.data = data;
        }
      },
    },
  },

    data: () => ({
      /**
       *  upstests: metadata of the UPS tests
       *  data: used to store apollo subscription result 
       */
      upstests: {
        UPS_COMMUNICATIE: {
          title: "Communicatie",
          description: "Deze test checkt of er communicatie is met de UPS.",
        },
        UPS_NOODVOEDING: {
          title: "Noodvoeding",
          description: "Deze test checkt of het systeem op de noodvoeding kan runnen.<br><br><b>Let op:</b><ul>" +
              "<li>Zet aan het begin van de test de UPS op noodstroom.</li>" +
              "<li>Zet na de test de UPS weer op hoofdstroom.</li></ul>",
        },
        UPS_HOOFDVOEDING: {
          title: "Hoofdvoeding",
          description: "Deze test checkt of de UPS functioneert terwijl het systeem op de hoofdvoeding runt.",
        }
      },

      data: {},
    }),

    computed: {
      /**
       *  testSessionId: currently selected test session ID. Globally stored in the Vuex store.
       */
      testSessionId() {
        return this.$store.getters.getCurrentTestSessionId;
      }
    },

    components: {
      Testen,
    }
}
</script>