<template><v-app>
  <div>
    <v-card style="display: block; margin-left: auto; margin-right: auto" max-width="50%" v-if="iRose2s.some(el => el.icpconId === undefined)">
      <v-card-title>Pagina kan niet worden geladen</v-card-title>
      <v-card-text>
        Vanwege wijzigingen aan de iRose2 testen is het niet mogelijk om oude iRose2 testen
        opnieuw te starten. De resultaten kunnen nog wel ingezien worden op de Testresultaten pagina.
      </v-card-text>
    </v-card>

    <div v-else class="pa-6" v-bind:class="{ 'blue lighten-5' : index % 2 === 1 }" v-for="(iRose, index) in iRose2s" :key="iRose.hardwareId">
      <h2 class="mb-4">iRose {{iRose.hardwareId}}</h2>

      <iRoseConfig :i-roses=createIRoseArray(iRose) :show-hardware-id="false" :show-delete-button="false" />

      <Testen :tests=irosetests :data=data hardware-key="hardwareId" :current-hardware-id=iRose.hardwareId :is-blue-background="index % 2 === 1"/>

    </div>
  </div>
</v-app></template>

<script>
/**
 *  The iRose page contains the iRose tests of all the iRoses configured in the currently selected test session.
 *  component: iRoseConfig - summary table of iRoses in the current iwks configuration
 *  component: Testen - displays all the tests with current executionState and metadata
 */
import gql from 'graphql-tag'
import iRoseConfig from '@/components/iRoseConfig.vue'
import Testen from '@/components/Testen.vue'

export default {
  name: 'iRose',
  apollo: {
    /**
     *  Subscription on all iRose tests for data used to display in the Testen component
     *  variable: currently selected test session
     *  If the first test (connection test) does not contain data then there are no iRose tests. Reroute to Sld4 testen page.
     */
      $subscribe: {
        subs: {
          query: gql`subscription iRose2Subscription($id: String!) {
          testSessionChanged(testSessionId: $id){
            iwksConfiguration { iRose2s { hardwareId, host, port, icpconId, icpconRelaisNummer, ssh { port, username, password } } },
            tests { id, hardwareId, testType, executions, { id, currentPhaseProgress, state, lastFatClientFeedback } }
          }
          }`,
          variables () {
            // Use vue reactive properties here
            return {
                id: this.testSessionId
            }
          },
          // Result hook
          // Don't forget to destructure `data`
          result ({ data }) {
            if (data.testSessionChanged.tests.filter(test => test.testType == "IROSE2_CONNECT").length === 0) {
              this.$router.push('/sld4testen');
            } else {
              this.data = data;
              this.iRose2s = data.testSessionChanged.iwksConfiguration.iRose2s;
            }
          }
        },
      },
      
    },

    data: () => ({
      /**
       *  iRose2s:  used to store apollo subscription result of the iRose configuration in iwksConfiguration
       *  irosetests: metadata of the iRose tests
       *  data: used to store apollo subscription result in its entirety 
       */
      iRose2s: [],

      irosetests: {
        IROSE2_CONNECT: {
          title: "Connectie",
          description: "Deze test checkt of er een connectie is gemaakt met de iRose2.",
        },
        IROSE2_RECONNECT: {
          title: "Herstarten connectie",
          description: "Deze test controleert of de iRose2 herstart kan worden.",
        }
      },

      data: {},
    }),

    computed: {
      /**
       *  testSessionId: currently selected test session ID. Globally stored in the Vuex store.
       */
      testSessionId() {
        return this.$store.getters.getCurrentTestSessionId;
      }
    },

    methods: {
      /**
       *  vue-prop-data: the component iRoseConfig expects an array. Convert the object to an array of 1 object. 
       */
      createIRoseArray(iRose) {
        let iRoses = [];
        iRoses.push(iRose);
        return iRoses;
      }
    },

    components: {
      iRoseConfig,
      Testen,
    }
}
</script>