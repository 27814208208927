<template><v-app>
  <div>
    <div class="pa-6" v-bind:class="{ 'blue lighten-5' : index % 2 === 1 }" v-for="(sld4, index) in sld4s" :key="sld4.hardwareId">
      <h2 class="mb-4">SLD4 {{sld4.hardwareId}}</h2>

      <Sld4Config :sld4s=createSld4Array(sld4) :show-hardware-id="false" :show-delete-button="false" />

      <Testen :tests=sld4tests :data=data hardware-key="hardwareId" :current-hardware-id=sld4.hardwareId :is-blue-background="index % 2 === 1"/>

    </div>
  </div>
</v-app></template>

<script>
/**
 *  The Sld4 page contains the sLd4 tests of all the sld4s configured in the currently selected test session.
 *  component: Sld4Config - summary table of sld4s in the current iwks configuration
 *  component: Testen - displays all the tests with current executionState and metadata
 */
import gql from 'graphql-tag'
import Sld4Config from '@/components/Sld4Config.vue'
import Testen from '@/components/Testen.vue'

export default {
  name: 'sld4',
  apollo: {
    /**
     *  Subscription on all sld4 tests for data used to display in the Testen component
     *  variable: currently selected test session
     *  If the first test (connection test) does not contain data then there are no sld4 tests. Reroute to LD16 testen page.
     */
      $subscribe: {
        subs: {
          query: gql`subscription sld4Subscription($id: String!) {
          testSessionChanged(testSessionId: $id){
            iwksConfiguration { sld4s { hardwareId, host, port, icpconId, icpconRelaisNummer, rijstroken } },
            tests { id, hardwareId, testType, executions, { id, currentPhaseProgress, state, lastFatClientFeedback } }
          }
          }`,
          variables () {
            // Use vue reactive properties here
            return {
                id: this.testSessionId
            }
          },
          // Result hook
          // Don't forget to destructure `data`
          result ({ data }) {
            if (data.testSessionChanged.tests.filter(test => test.testType == "SLD4_CONNECT").length === 0) {
              this.$router.push('/ld16testen');
            } else {
              this.data = data;
              this.sld4s = data.testSessionChanged.iwksConfiguration.sld4s;
            }
          }
        },
      },
      
    },

    data: () => ({
      /**
       *  sld4s:  used to store apollo subscription result of the sld4 configuration in iwksConfiguration
       *  sld4tests: metadata of the sld4 tests
       *  data: used to store apollo subscription result in its entirety 
       */
      sld4s: [],

      sld4tests: {
        SLD4_CONNECT: {
          title: "Connectie",
          description: "Deze test checkt of er een connectie is gemaakt met de fl-comserver.",
        },
        SLD4_RECONNECT: {
          title: "Herstarten connectie",
          description: "Deze test checkt of de fl-comserver herstart kan worden",
        }
      },

      data: {},
    }),

    computed: {
      /**
       *  testSessionId: currently selected test session ID. Globally stored in the Vuex store.
       */
      testSessionId() {
        return this.$store.getters.getCurrentTestSessionId;
      }
    },

    methods: {
      /**
       *  vue-prop-data: the component Sld4Config expects an array. Convert the object to an array of 1 object.
       */
      createSld4Array(sld4) {
        let sld4s = [];
        sld4s.push(sld4);
        return sld4s;
      }
    },

    components: {
      Sld4Config,
      Testen,
    }
}
</script>