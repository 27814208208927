<template><v-app>
  <div>
    <v-card style="display: block; margin-left: auto; margin-right: auto" max-width="50%" v-if="icpcons.length === 0">
      <v-card-title>Pagina kan niet worden geladen</v-card-title>
      <v-card-text>
        Vanwege wijzigingen in de ICP CON test is het niet mogelijk om oude ICP CON testen
        opnieuw te starten. De resultaten kunnen nog wel ingezien worden op de Testresultaten pagina.
      </v-card-text>
    </v-card>

    <div v-else class="pa-6" v-bind:class="{ 'blue lighten-5' : index % 2 === 1 }" v-for="(icpcon, index) in icpcons" :key="icpcon.icpconId">
      <h2 class="mb-4">ICP CON {{icpcon.icpconId}}</h2>

      <Testen :tests=icpcontests :data=data hardware-key="icpconId" :current-hardware-id=icpcon.icpconId :is-blue-background="index % 2 === 1"/>

    </div>
  </div>
</v-app></template>

<script>
/**
 *  The icp con page contains the icp con tests of the currently selected test session.
 *  component: Testen - displays all the tests with current executionState and metadata
 */
import gql from 'graphql-tag'
import Testen from '@/components/Testen.vue'

export default {
  name: 'icp-con',
  apollo: {
    /**
     *  Subscription on all icp-con tests for data used to display in the Testen component
     *  variable: currently selected test session
     */
    $subscribe: {
      subs: {
        query: gql`subscription myIcpConSubscription($id: String!) {
          testSessionChanged(testSessionId: $id){
            iwksConfiguration { icpcons { icpconId } },
            tests { id, hardwareId, testType, executions, { id, currentPhaseProgress, state, lastFatClientFeedback } }
            }
          }`,
        variables () {
          // Use vue reactive properties here
          return {                
              id: this.testSessionId
          }
        },
        // Result hook
        // Don't forget to destructure `data`
        result ({ data }) {
          if (data.testSessionChanged.tests.filter(test => test.testType == "ICP_CON_CONNECT").length === 0) {
            this.$router.push('/irosetesten');
          } else {
            this.data = data;
            this.icpcons = data.testSessionChanged.iwksConfiguration.icpcons;
          }
        }
      },
    },
  },

    data: () => ({
      icpcons: [],
      icpcontests: {
        ICP_CON_CONNECT: {
          title: "Connectie",
          description: "Deze test checkt of er communicatie is met de ICP CON en haalt metadata op.",
        },
        ICP_CON_DEFAULTS: {
          title: "Standaard waarden",
          description: "De 'power-on' en de 'safe' waarden dienen standaard ingeschakeld te zijn.",
        }
      },

      data: {},
    }),

    computed: {
      /**
       *  testSessionId: currently selected test session ID. Globally stored in the Vuex store.
       */
      testSessionId() {
        return this.$store.getters.getCurrentTestSessionId;
      }
    },

    components: {
      Testen,
    }
}
</script>