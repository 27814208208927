<template><v-app>
  <div>
    <div class="pa-6">        
      <h2 class="mb-4">LAN Switch</h2>

      <Testen :tests=lanswitchtests :data=data current-hardware-id="LAN"/>

    </div>
  </div>
</v-app></template>

<script>
/**
 *  The LAN Switch page contains the LAN Switch connections tests of the currently selected test session.
 *  component: Testen - displays all the tests with current executionState and metadata
 */
import gql from 'graphql-tag'
import Testen from '@/components/Testen.vue'

export default {
  name: 'lan-switch',
  apollo: {
    /**
     *  Subscription on all UPS tests for data used to display in the Testen component
     *  variable: currently selected test session
     */
    $subscribe: {
      subs: {
        query: gql`subscription myLANSwitchSubscription($id: String!) {
          testSessionChanged(testSessionId: $id){
            tests { id, hardwareId, testType, executions, { id, currentPhaseProgress, state, lastFatClientFeedback } }
          }
        }`,
        variables () {
          // Use vue reactive properties here
          return {                
              id: this.testSessionId
          }
        },
        // Result hook
        // Don't forget to destructure `data`
        result ({ data }) {
          if (data.testSessionChanged.tests.filter(test => test.testType == "LAN_SWITCH_PING_1").length === 0) {
            this.$router.push('/icpcontesten');
          } else {
            this.data = data;
          }
        }
      },
    },
  },

    data: () => ({
      /**
       *  upstests: metadata of the UPS tests
       *  data: used to store apollo subscription result 
       */
      lanswitchtests: {
        LAN_SWITCH_PING_1: {
          title: "Connectie test LAN switch 1",
          description: "Deze test checkt of er communicatie is met de LAN switch 1.",
        },
        LAN_SWITCH_PING_2: {
          title: "Connectie test LAN switch 2",
          description: "Deze test checkt of er communicatie is met de LAN switch 2.",
        }
      },

      data: {},
    }),

    computed: {
      /**
       *  testSessionId: currently selected test session ID. Globally stored in the Vuex store.
       */
      testSessionId() {
        return this.$store.getters.getCurrentTestSessionId;
      }
    },

    components: {
      Testen,
    }
}
</script>